@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}
body{
    background: #444;
}
.header{
    background: #333;
    padding: 20px 100px;
    color: aliceblue;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

nav ul{
    display: flex;
}
nav ul li{
    list-style: none;
    margin-right: 30px;
}
nav ul li a{
    font-weight: bold;
    color: aliceblue;
    text-decoration: none;
}
.searchBox{
    display: flex;
    border-radius: 0px 10px 10px 0px;

}
.searchText{
    padding: 10px 10px;
    border-radius: 10px 0px 0px 10px;
    border: none;
    outline: none;
    box-shadow: inset 0 5px 9px gray;
    /* color: aliceblue; */
}
.search-btn{
    background: rgb(0, 0, 0);
    border:none;
    border-radius: 0px 10px 10px 0px;
    padding: 12px;
    color: aliceblue;
}

.container{
    margin: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* card page */
.movie{
    box-shadow:  0 5px 10px gray;
    width: 250px;
    background-color: aliceblue;
    margin: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.movie .poster{
    width: 100%;
    height: 250px;

}
.movie-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding:  20px; */
}
.movie-details .box{
    width: 100%;
    /* padding-left: 15px; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.movie-details .title{
    width: 160px;
    text-align: center;
}
.box{
    padding: 0 20px 10px 20px;
}